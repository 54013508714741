<template>
  <div>
    <l-card :title="$t('import-areas.title')" overflow-visible>
      <div class="flex justify-between items-center">
        <div class="flex-1 mr-6">
          <l-field
            :label="$t('import-areas.name-placeholder')"
            label-font-size-class="text-xs"
          >
            <l-input
              v-model="areaName"
              :placeholder="$t('import-areas.name-placeholder')"
              class="text-sm"
              :disabled="importing"
            />
          </l-field>
        </div>
        <div class="flex-1 mr-6">
          <l-field
            :label="$t('import-catalog.csv')"
            label-font-size-class="text-xs"
          >
            <l-upload
              @input="fileUpdated"
              :disabled="false"
              :drag-drop="true"
              :accept="'.csv'"
              class="flex-1 my-2 h-12"
            >
              <div
                v-if="!file"
                class="flex items-center justify-center flex-1 text-sm text-cente text-center cursor-pointer p-2"
              >
                <icon name="upload" class="text-violet mr-3" />
                <span v-html="$t('import-csv-catalog.upload-file')"></span>
              </div>
              <div
                v-else
                class="flex flex-1 items-center p-2 text-sm text-center text-blue rounded-small"
              >
                <div class="flex-shrink-0 cursor-pointer mr-2">
                  <icon name="catalog" class="text-blue" />
                </div>
                <div class="flex-1 text-blue text-left">
                  {{ file.name }}
                </div>
                <div
                  @click.prevent="resetInput"
                  class="flex-shrink-0 cursor-pointer"
                >
                  <icon name="close" class="text-blue" />
                </div>
              </div>
            </l-upload>
          </l-field>
        </div>
        <div
          v-if="!importing"
          class="border border-violet text-violet rounded-lg w-1/4 text-center py-2 flex justify-center"
          :class="{ 'opacity-50': !valid, 'cursor-pointer': valid }"
          @click="importArea"
        >
          {{ $t('import-external-catalog.import') }}
        </div>
        <div v-else class="text-blue text-center flex w-1/4 justify-center">
          <div class="w-6 mr-4">
            <lottie-animation path="lottie-loading.json" />
          </div>
          {{ $t('import-external-catalog.importing') }}
        </div>
      </div>
    </l-card>
  </div>
</template>

<script>
import LCard from '@last/core-ui/components/LCard'
import LField from '@last/core-ui/components/LField.vue'
import { mapState } from 'vuex'
import LInput from '@last/core-ui/components/LInput.vue'
import LUpload from '@last/core-ui/components/LUpload.vue'
import LottieAnimation from 'lottie-vuejs/src/LottieAnimation.vue'
import lastUtils from '@last/core/src/lastUtils'
import Icon from '@last/core-ui/components/Icon.vue'
import api from '@/api.js'

export default {
  name: 'ImportAreas',
  components: {
    LCard,
    LField,
    LInput,
    LUpload,
    LottieAnimation,
    Icon
  },
  data() {
    return {
      areaName: null,
      file: null,
      importing: false,
      csv: null
    }
  },
  props: {},
  methods: {
    async importArea() {
      if (this.valid) {
        this.importing = true
        try {
          await api.post(
            `/support/locations/${this.selectedLocation.id}/areas`,
            {
              area: this.getJSON(),
              name: this.areaName
            }
          )
          this.resetInput()
          this.$lnotification.create({
            title: this.$t('import-areas.import-succeeded'),
            icon: 'check',
            iconColor: 'green'
          })
        } catch (err) {
          this.$lnotification.create({
            title: `${this.$t('import-areas.import-failed')}: ${
              err.response.request.response
            }`,
            icon: 'close',
            iconColor: 'red'
          })
        }
        this.importing = false
      }
    },
    getJSON() {
      const lines = this.csv.split('\n')
      return lines.map(line => ({
        lat: Number(line.split(',')[1]),
        lng: Number(line.split(',')[0])
      }))
    },
    async fileUpdated(file) {
      this.file = file
      this.csv = await file.text()
    },
    resetInput() {
      this.areaName = null
      this.file = null
      this.csv = null
    }
  },
  computed: {
    ...mapState('organizations', ['selectedLocation']),
    valid() {
      return (
        !lastUtils.isNullOrEmpty(this.areaName) &&
        !lastUtils.isNullOrEmpty(this.csv)
      )
    }
  }
}
</script>

<style scoped>
::v-deep .separator {
  height: 1px;
}

::v-deep .provider-row:last-child .separator {
  height: 0;
}
</style>
