<template>
  <div class="w-64 sm:w-96 w-full md:px-8 py-2">
    <div class="flex w-full py-4 items-center space-x-2">
      <div
        class="w-7 h-7 flex justify-center items-center cursor-pointer rounded-full hover:bg-gray-200"
        :class="{ 'pointer-events-none opacity-50': cantGoBack }"
        @click="previousMonth()"
      >
        <icon name="arrow-left" class="text-blue" small />
      </div>
      <div v-if="showSelectors" class="flex flex-1 space-x-2">
        <l-select v-model="month" :options="months" class="flex-1" />
        <l-select v-model="year" :options="years" class="flex-1" />
      </div>
      <div v-else class="flex-1 font-body text-secondary text-center">
        {{ month }} {{ year }}
      </div>
      <div
        class="w-7 h-7 flex justify-center items-center rounded-full cursor-pointer hover:bg-gray-200"
        @click="nextMonth()"
      >
        <icon name="arrow-right" class="text-blue" small />
      </div>
    </div>
    <div class="flex w-full text-gray-400 text-xs pb-3">
      <div v-for="day in weekdays" :key="day" class="flex-1 text-center">
        {{ day }}
      </div>
    </div>
    <div class="w-full pb-2">
      <div v-for="(row, index) in days" :key="index" class="flex-1 flex">
        <div
          v-for="day in row"
          :key="day.date"
          @click="select(day)"
          class="flex-1 text-secondary text-sm flex justify-center py-0.5"
          :class="{ 'opacity-30': !day.enabled, 'cursor-pointer': day.enabled }"
        >
          <div
            class="w-8 h-8 rounded-full leading-8 text-center"
            :class="{
              'hover:bg-gray-200': day.enabled && !day.selected,
              'bg-violet text-white': day.selected
            }"
          >
            {{ day.day }}
          </div>
        </div>
      </div>
    </div>
    <div v-if="showTime">
      <div class="flex justify-center">
        <div
          class="flex justify-center bg-gray-100 text-violet px-6 py-2 rounded-lg"
        >
          <time-picker
            v-model="time"
            class="rounded-xl w-1/2"
            @input="updateTime"
          ></time-picker>
        </div>
      </div>
      <div class="flex justify-center items-center my-3">
        <div
          class="rounded-lg w-3/4 text-violet uppercase border border-violet py-2 px-5 text-center cursor-pointer hover:bg-violet hover:text-white transition"
          @click="applyChanges"
        >
          {{ $t('ctas.apply') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from '@last/core-ui/components/Icon.vue'
import LSelect from './LSelect.vue'
import TimePicker from './TimePicker.vue'
import baseMoment from 'moment'
import * as MomentRange from 'moment-range'
const moment = MomentRange.extendMoment(baseMoment)

export default {
  name: 'Calendar',
  emits: ['updateSelectedDate', 'monthChanged'],
  props: {
    selectedDate: {
      type: String,
      default: null
    },
    unavailableDates: {
      type: Array,
      default: () => []
    },
    showSelectors: {
      type: Boolean,
      default: false
    },
    showTime: {
      type: Boolean,
      default: false
    },
    maxDate: {
      type: Date,
      default: null
    },
    onlyFuture: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      currentDate: moment(),
      editingDate: moment()
    }
  },
  mounted() {
    if (this.selectedDate) {
      this.currentDate = moment(this.selectedDate)
      this.editingDate = moment(this.selectedDate)
    }
    this.$emit('monthChanged', this.currentDate.format('YYYY-MM'))
  },
  computed: {
    years() {
      let years = []
      let pastYears = [
        ...Array(3)
          .fill(0)
          .map((_, i) => moment().year() - i - 1)
      ]
      let futureYears = [
        moment().year(),
        ...Array(3)
          .fill(0)
          .map((_, i) => moment().year() + i + 1)
      ]
      if (this.onlyFuture) {
        years = futureYears
      } else {
        years = [...pastYears, ...futureYears]
      }
      return years.sort().map(y => {
        return { label: y, value: y.toString() }
      })
    },
    weekdays() {
      let week = moment().startOf('isoWeek').locale('es')
      return Array.apply(null, Array(7)).map((_, i) => {
        return week
          .isoWeekday(i + 1)
          .format('ddd')
          .toUpperCase()
          .slice(0, 3)
      })
    },
    days() {
      let firstWeek = moment(this.currentDate)
        .startOf('month')
        .startOf('isoWeek')
      if (firstWeek.isoWeekday() !== 1) {
        firstWeek.subtract(1, 'isoWeek')
      }
      return this.chunks(
        [
          ...moment.rangeFromInterval('days', 6 * 7 - 1, firstWeek).by('days')
        ].map(day => {
          let date = day.format('YYYY-MM-DD')
          let maxDateFilter = this.maxDate ? day < moment(this.maxDate) : true
          return {
            date,
            day: day.date(),
            enabled:
              day.month() === this.currentDate.month() &&
              !this.unavailableDates.includes(date) &&
              (!this.onlyFuture || day >= moment().startOf('day')) &&
              maxDateFilter,
            selected: date === this.editingDate.format('YYYY-MM-DD')
          }
        }),
        7
      )
    },
    cantGoBack() {
      return (
        this.onlyFuture &&
        moment().month() === this.currentDate.month() &&
        moment().year() === this.currentDate.year()
      )
    },
    months() {
      let months = (months = moment.monthsShort().map(m => {
        return { label: m, value: m }
      }))
      let now = moment()
      if (this.currentDate.year() === now.year() && this.onlyFuture) {
        months.splice(0, now.month())
      }
      return months
    },
    month: {
      get() {
        if (this.showSelectors) {
          return this.currentDate.format('MMM')
        } else {
          return this.currentDate.format('MMMM')
        }
      },
      set(value) {
        this.currentDate.set('month', moment(value, 'MMM').month())
        this.currentDate = moment(this.currentDate.toDate())
      }
    },
    year: {
      get() {
        return this.currentDate.format('YYYY')
      },
      set(value) {
        this.currentDate.set('year', value)
        this.currentDate = moment(this.currentDate.toDate())
      }
    },
    time: {
      get() {
        return this.editingDate.format('HH:mm')
      },
      set(value) {
        this.editingDate.set('hour', moment(value).hour())
        this.editingDate.set('minute', moment(value).minutes())
        this.editingDate = moment(this.editingDate.toDate())
      }
    }
  },
  methods: {
    nextMonth() {
      this.currentDate = moment(this.currentDate.add(1, 'month'))
    },
    previousMonth() {
      this.currentDate = moment(this.currentDate.subtract(1, 'month'))
    },
    chunks(list, size) {
      var res = []
      for (var i = 0; i < list.length; i += size) {
        res.push(list.slice(i, i + size))
      }
      return res
    },
    select(date) {
      if (date.enabled) {
        let time = this.editingDate.format('HH:mm')
        this.editingDate = moment(date.date + ' ' + time)
        if (!this.showTime) {
          this.$emit('updateSelectedDate', this.editingDate.toDate())
        }
      }
    },
    updateTime(value) {
      this.time = moment(value, 'HH:mm')
    },
    applyChanges() {
      this.currentDate = moment(this.editingDate.toDate())
      this.$emit('updateSelectedDate', moment(this.editingDate).toDate())
      this.$emit('close')
    }
  },
  watch: {
    currentDate(currentDate) {
      this.$emit('monthChanged', currentDate.format('YYYY-MM'))
    }
  },
  components: {
    Icon,
    LSelect,
    TimePicker
  }
}
</script>

<style scoped>
.bg-red {
  background-color: red;
}
</style>
