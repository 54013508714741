var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bg-white h-full flex flex-row"},[_c('div',{staticClass:"organizations-w p-5 overflow-hidden h-full"},[_c('div',{staticClass:"mt-3"},[_c('div',{staticClass:"flex flex-row justify-between items-center"},[_c('div',{staticClass:"uppercase text-blue font-medium text-sm"},[_vm._v(" "+_vm._s(_vm.$t('side-menu.customers-list'))+" ")]),_c('div',{staticClass:"bg-violet border-violet-b rounded-full p-2 cursor-pointer",on:{"click":_vm.goToCreateOrganization}},[_c('icon',{staticClass:"text-white",attrs:{"small":"","name":"plus"}})],1)]),_c('div',{staticClass:"self-center"},[_c('l-input',{attrs:{"val":"","icon":"search","placeholder":_vm.$t('side-menu.search-organizations'),"small":"","iconClass":"text-violet"},model:{value:(_vm.customerQuery),callback:function ($$v) {_vm.customerQuery=$$v},expression:"customerQuery"}})],1)]),_c('div',{staticClass:"overflow-y-scroll h-full pb-24 show-scroll -mr-5"},[(_vm.customerQuery === '')?_c('div',_vm._l((_vm.filteredOrganizations),function(organization){return _c('div',{key:organization.id,staticClass:"px-3 py-2 my-2 rounded-lg cursor-pointer",class:{
            'bg-violet text-white border-violet-b':
              organization.id ===
              (_vm.selectedOrganization && _vm.selectedOrganization.id),
            'text-blue':
              organization.id !==
              (_vm.selectedOrganization && _vm.selectedOrganization.id)
          },on:{"click":function($event){return _vm.organizationClickedHandler(organization)}}},[_vm._v(" "+_vm._s(organization.name)+" ")])}),0):_c('div',[(_vm.filteredCustomersList.filteredOrganizations.length > 0)?_c('div',[_c('div',{staticClass:"uppercase text-gray-400 text-sm pt-5"},[_vm._v(" "+_vm._s(_vm.$t('side-menu.organizations'))+" ")]),_vm._l((_vm.filteredCustomersList.filteredOrganizations),function(organization){return _c('div',{key:organization.id,staticClass:"px-3 pb-2 my-2 rounded-lg cursor-pointer",class:{
              'bg-violet text-white border-violet-b':
                organization.id ===
                (_vm.selectedOrganization && _vm.selectedOrganization.id),
              'text-blue':
                organization.id !==
                (_vm.selectedOrganization && _vm.selectedOrganization.id)
            },on:{"click":function($event){return _vm.organizationClickedHandler(organization)}}},[_vm._v(" "+_vm._s(organization.name)+" ")])})],2):_vm._e(),(
            _vm.filteredCustomersList.filteredLocations.length > 0 &&
            _vm.selectedOrganization
          )?_c('div',[_c('div',{staticClass:"uppercase text-gray-400 text-sm pt-5"},[_vm._v(" "+_vm._s(_vm.$t('side-menu.locations'))+" ")]),_vm._l((_vm.filteredCustomersList.filteredLocations),function(location){return _c('div',{key:location.id,staticClass:"px-3 pb-2 my-2 rounded-lg cursor-pointer text-blue",on:{"click":function($event){return _vm.findOrganizationAndSelect(location.organizationId, location)}}},[_vm._v(" "+_vm._s(location.name)+" ")])})],2):_vm._e(),(_vm.filteredCustomersList.filteredBrands.length > 0)?_c('div',[_c('div',{staticClass:"uppercase text-gray-400 text-sm pt-5"},[_vm._v(" "+_vm._s(_vm.$t('side-menu.virtual-brands'))+" ")]),_vm._l((_vm.filteredCustomersList.filteredBrands),function(brand){return _c('div',{key:brand.id,staticClass:"px-3 pb-2 my-2 rounded-lg cursor-pointer text-blue",on:{"click":function($event){return _vm.findOrganizationAndSelect(brand.organizationId)}}},[_vm._v(" "+_vm._s(brand.name)+" ")])})],2):_vm._e()])])]),(_vm.selectedOrganization)?_c('div',{staticClass:"bg-blue-600 p-5 text-white locations-w h-full"},[_c('div',{staticClass:"mb-5 mt-3"},[_c('div',{staticClass:"uppercase font-medium text-sm"},[_vm._v(" "+_vm._s(_vm.$t('side-menu.location-list'))+" ")]),_c('div',{staticClass:"self-center"},[_c('l-input',{attrs:{"val":"","icon":"search","placeholder":_vm.$t('side-menu.search-locations'),"small":"","iconClass":"text-violet","theme":"dark"},model:{value:(_vm.locationQuery),callback:function ($$v) {_vm.locationQuery=$$v},expression:"locationQuery"}})],1)]),_c('div',{staticClass:"overflow-y-scroll h-full pb-24"},_vm._l((_vm.locationsMenuContent),function(location){return _c('div',{key:location.id,staticClass:"px-3 py-2 my-2 rounded-lg cursor-pointer text-white",class:{
          'bg-blue text-white':
            location.id === (_vm.selectedLocation && _vm.selectedLocation.id)
        },on:{"click":function($event){return _vm.locationClickedHandler(location)}}},[_vm._v(" "+_vm._s(location.name)+" ")])}),0)]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }