<template>
  <div>
    <l-card :title="'Last Payments'">
      <l-button
        v-if="step === 'request'"
        @click="requestPermission"
        :loading="requesting"
      >
        Request
      </l-button>
      <div v-else-if="step === 'sign'">Waiting for user to sign</div>
      <div v-else-if="step === 'done'">Done!</div>
      <div v-if="step === 'done'" class="flex flex-col mt-4">
        <div
          v-for="device in adyenDevices"
          :key="device.id"
          class="hover:bg-gray-100 p-2 border-b border-gray-200 flex items-center justify-between"
        >
          {{ device.poiid }}
          <icon
            name="trash"
            class="cursor-pointer text-gray-400"
            @click="() => deleteDevice(device.id)"
          />
        </div>
      </div>
    </l-card>
  </div>
</template>

<script>
import LCard from '@last/core-ui/components/LCard'
import LButton from '@last/core-ui/components/LButton'
import Icon from '@last/core-ui/components/Icon.vue'
import api from '@/api.js'
import { mapState } from 'vuex'

export default {
  name: 'LastPayments',
  components: {
    LCard,
    LButton,
    Icon
  },
  data() {
    return {
      receivePayments: {},
      requesting: false,
      adyenDevices: []
    }
  },
  props: {},
  async mounted() {
    await this.refreshPermissions()
    if (this.step === 'done') await this.refreshAdyenDevices()
  },
  methods: {
    async refreshPermissions() {
      try {
        const res = await api.get(
          `/support/last-payments/${this.selectedLocation.id}/permissions/receive-payments`
        )
        this.receivePayments = res.data?.receivePayments ?? {}
      } catch (e) {
        this.receivePayments = {}
      }
    },
    async refreshAdyenDevices() {
      try {
        const res = await api.get(
          `/support/last-payments/${this.selectedLocation.id}/devices`
        )
        this.adyenDevices = res.data ?? []
      } catch (e) {
        this.adyenDevices = []
      }
    },
    async requestPermission() {
      try {
        this.requesting = true
        await api.post(
          `/support/last-payments/${this.selectedLocation.id}/permissions/receive-payments`
        )
        await this.refreshPermissions()
      } catch (e) {
        this.$lnotification.create({
          title: 'error',
          icon: 'close',
          iconColor: 'red'
        })
      }
      this.requesting = useFloorplanStore
    },
    deleteDevice(deviceId) {
      this.$ldialog({
        title: `Eliminar dispositivo ${deviceId}`,
        onConfirm: async () => {
          await api.delete(
            `/support/last-payments/${this.selectedLocation.id}/devices/${deviceId}`
          )
          await this.refreshAdyenDevices()
        }
      })
    }
  },
  computed: {
    ...mapState('organizations', ['selectedLocation']),
    step() {
      if (!this.receivePayments) return null
      if (!this.receivePayments.requested) {
        return 'request'
      }
      if (!this.receivePayments.allowed) {
        return 'sign'
      }
      return 'done'
    }
  },
  watch: {}
}
</script>

<style scoped>
.separator {
  height: 1px;
}
</style>
