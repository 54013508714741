import Vue from 'vue'
import VueRouter from 'vue-router'
import OrganizationSettings from '@/components/OrganizationSettings.vue'
import LocationSettings from '@/components/LocationSettings.vue'
import Support from '@/components/Support.vue'
import Settings from '@/components/Settings.vue'
import Login from '@/components/Login.vue'
import PosErrorManager from '@/components/PosErrorManager.vue'
import Billing from '@/components/Billing.vue'
import store from '@/store/index'
import DeletedLocations from '@/components/DeletedLocations.vue'
import AppVersions from '@/components/AppVersions.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Support,
    redirect: {
      name: 'global'
    },
    children: [
      {
        name: 'global',
        path: '/organization/:organizationId/global',
        component: OrganizationSettings
      },
      {
        name: 'location',
        path: '/organization/:organizationId/location/:locationId',
        component: LocationSettings
      },
      {
        name: 'settings',
        path: '/settings',
        component: Settings
      },
      {
        name: 'errors',
        path: '/settings/errors',
        component: PosErrorManager
      },
      {
        name: 'billing',
        path: '/settings/billing',
        component: Billing
      },
      {
        name: 'deletedLocations',
        path: '/settings/deletedLocations',
        component: DeletedLocations
      },
      {
        name: 'appVersions',
        path: '/settings/appVersions',
        component: AppVersions
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  let needsAuth = !['login'].includes(to.name)
  let isAuthenticated = store.getters['auth/isAuthenticated']
  if (needsAuth && !isAuthenticated) {
    next({
      name: 'login',
      query: { redirect: to.fullPath }
    })
  } else {
    next()
  }
})

export default router
