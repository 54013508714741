<template>
  <div class="bg-white h-full flex flex-row">
    <div class="organizations-w p-5 overflow-hidden h-full">
      <div class="mt-3">
        <div class="flex flex-row justify-between items-center">
          <div class="uppercase text-blue font-medium text-sm">
            {{ $t('side-menu.customers-list') }}
          </div>
          <div
            class="bg-violet border-violet-b rounded-full p-2 cursor-pointer"
            @click="goToCreateOrganization"
          >
            <icon small name="plus" class="text-white" />
          </div>
        </div>
        <div class="self-center">
          <l-input
            val
            v-model="customerQuery"
            icon="search"
            :placeholder="$t('side-menu.search-organizations')"
            small
            iconClass="text-violet"
          />
        </div>
      </div>
      <div class="overflow-y-scroll h-full pb-24 show-scroll -mr-5">
        <div v-if="customerQuery === ''">
          <div
            v-for="organization in filteredOrganizations"
            :key="organization.id"
            class="px-3 py-2 my-2 rounded-lg cursor-pointer"
            :class="{
              'bg-violet text-white border-violet-b':
                organization.id ===
                (selectedOrganization && selectedOrganization.id),
              'text-blue':
                organization.id !==
                (selectedOrganization && selectedOrganization.id)
            }"
            @click="organizationClickedHandler(organization)"
          >
            {{ organization.name }}
          </div>
        </div>
        <div v-else>
          <div v-if="filteredCustomersList.filteredOrganizations.length > 0">
            <div class="uppercase text-gray-400 text-sm pt-5">
              {{ $t('side-menu.organizations') }}
            </div>
            <div
              v-for="organization in filteredCustomersList.filteredOrganizations"
              :key="organization.id"
              class="px-3 pb-2 my-2 rounded-lg cursor-pointer"
              :class="{
                'bg-violet text-white border-violet-b':
                  organization.id ===
                  (selectedOrganization && selectedOrganization.id),
                'text-blue':
                  organization.id !==
                  (selectedOrganization && selectedOrganization.id)
              }"
              @click="organizationClickedHandler(organization)"
            >
              {{ organization.name }}
            </div>
          </div>
          <div
            v-if="
              filteredCustomersList.filteredLocations.length > 0 &&
              selectedOrganization
            "
          >
            <div class="uppercase text-gray-400 text-sm pt-5">
              {{ $t('side-menu.locations') }}
            </div>
            <div
              v-for="location in filteredCustomersList.filteredLocations"
              :key="location.id"
              class="px-3 pb-2 my-2 rounded-lg cursor-pointer text-blue"
              @click="
                findOrganizationAndSelect(location.organizationId, location)
              "
            >
              {{ location.name }}
            </div>
          </div>
          <div v-if="filteredCustomersList.filteredBrands.length > 0">
            <div class="uppercase text-gray-400 text-sm pt-5">
              {{ $t('side-menu.virtual-brands') }}
            </div>
            <div
              v-for="brand in filteredCustomersList.filteredBrands"
              :key="brand.id"
              class="px-3 pb-2 my-2 rounded-lg cursor-pointer text-blue"
              @click="findOrganizationAndSelect(brand.organizationId)"
            >
              {{ brand.name }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="bg-blue-600 p-5 text-white locations-w h-full"
      v-if="selectedOrganization"
    >
      <div class="mb-5 mt-3">
        <div class="uppercase font-medium text-sm">
          {{ $t('side-menu.location-list') }}
        </div>
        <div class="self-center">
          <l-input
            val
            v-model="locationQuery"
            icon="search"
            :placeholder="$t('side-menu.search-locations')"
            small
            iconClass="text-violet"
            theme="dark"
          />
        </div>
      </div>
      <div class="overflow-y-scroll h-full pb-24">
        <div
          v-for="location in locationsMenuContent"
          :key="location.id"
          class="px-3 py-2 my-2 rounded-lg cursor-pointer text-white"
          :class="{
            'bg-blue text-white':
              location.id === (selectedLocation && selectedLocation.id)
          }"
          @click="locationClickedHandler(location)"
        >
          {{ location.name }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LInput from '@last/core-ui/components/LInput.vue'
import Icon from '@last/core-ui/components/Icon.vue'
import { mapState, mapActions } from 'vuex'

export default {
  name: 'SideMenu',
  data() {
    return {
      customerQuery: '',
      locationQuery: ''
    }
  },
  methods: {
    ...mapActions('organizations', ['selectOrganization', 'selectLocation']),
    organizationClickedHandler(organization) {
      if (
        this.selectedOrganization &&
        this.selectedOrganization.id === organization.id
      ) {
        return
      }
      this.selectOrganization(organization)
      this.selectLocation({
        name: this.$t('side-menu.global-settings'),
        id: -1
      })
      this.$router.push({
        name: 'global',
        params: { organizationId: organization.id }
      })
    },
    locationClickedHandler(location) {
      if (this.selectedLocation && this.selectedLocation.id === location.id) {
        return
      }
      if (location.id === -1) {
        this.selectLocation({
          name: this.$t('side-menu.global-settings'),
          id: -1
        })
        this.$router.push({
          name: 'global',
          params: { organizationId: this.selectedOrganization.id }
        })
      } else {
        this.selectLocation(location)
        this.$router.push({
          name: 'location',
          params: {
            organizationId: this.selectedOrganization.id,
            locationId: this.selectedLocation.id
          }
        })
      }
    },
    findOrganizationAndSelect(organizationId, location) {
      let organization = this.organizations.find(
        organization => organization.id === organizationId
      )
      this.organizationClickedHandler(organization)
      if (location) {
        this.locationClickedHandler(location)
      }
    },
    stringIncludes(container, children) {
      if (!container) {
        return false
      }
      if (!children) {
        return true
      }
      let normalizedContainer = container
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .replace("'", '')
      let normalizedChildren = children
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .replace("'", '')
      return normalizedContainer.includes(normalizedChildren)
    },
    goToCreateOrganization() {
      window.open(
        `${process.env.VUE_APP_LAST_ADMIN_URL}/support/login?supportToken=${this.accessToken}&redirect=createOrganization`
      )
    }
  },
  computed: {
    ...mapState('organizations', [
      'organizations',
      'virtualBrands',
      'selectedOrganization',
      'selectedLocation'
    ]),
    ...mapState('auth', ['accessToken']),
    filteredOrganizations() {
      return this.organizations.filter(
        organization =>
          this.stringIncludes(organization.name, this.customerQuery) ||
          this.stringIncludes(organization.id, this.customerQuery)
      )
    },
    filteredCustomersList() {
      let filteredBrands = this.virtualBrands.filter(brand =>
        this.stringIncludes(brand.name, this.customerQuery)
      )
      let filteredLocations = this.allLocations.filter(
        location =>
          this.stringIncludes(location.name, this.customerQuery) ||
          this.stringIncludes(location.id, this.customerQuery)
      )

      return {
        filteredOrganizations: this.filteredOrganizations,
        filteredLocations,
        filteredBrands
      }
    },
    allLocations() {
      return this.organizations.flatMap(organization => organization.locations)
    },
    filteredLocations() {
      return this.selectedOrganization
        ? this.selectedOrganization.locations.filter(location =>
            this.stringIncludes(location.name, this.locationQuery)
          )
        : []
    },
    locationsMenuContent() {
      return [
        { name: this.$t('side-menu.global-settings'), id: -1 },
        ...this.filteredLocations
      ]
    }
  },
  components: {
    LInput,
    Icon
  },
  watch: {
    organizations() {
      if (this.$route.params.organizationId) {
        let organization = this.organizations.find(
          organization => organization.id === this.$route.params.organizationId
        )
        this.selectOrganization(organization)
        if (this.$route.params.locationId) {
          let location = organization.locations.find(
            location => location.id === this.$route.params.locationId
          )
          this.selectLocation(location)
        } else {
          this.selectLocation({
            name: this.$t('side-menu.global-settings'),
            id: -1
          })
        }
      } else {
        this.organizationClickedHandler(this.organizations[0])
      }
    }
  }
}
</script>

<style scoped>
.organizations-w {
  width: 17.5rem;
}
.locations-w {
  width: 15rem;
}
</style>
