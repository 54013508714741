<template>
  <div>
    <l-card :title="$t('subscriptions.lastpos')">
      <div v-if="subscriptions.length">
        <div v-for="subscription in subscriptions" :key="subscription.id">
          <div v-if="subscription && subscription.type === 'pos'">
            <div
              class="flex flex-row justify-between items-center text-blue mt-10"
            >
              <div class="flex">
                <div class="mr-2">{{ $t('subscriptions.status') }}:</div>
                {{ subscription.status }}
              </div>
              <div class="flex">
                <div class="mr-2">SubscriptionId:</div>
                <div class="mr-2 text-violet">
                  {{ subscription.zohoSubscriptionId }}
                </div>
                <div class="flex items-center">
                  <icon
                    name="external"
                    small
                    class="cursor-pointer"
                    @click="
                      goToUrl(
                        `https://subscriptions.zoho.eu/app#/subscriptions/${subscription.zohoSubscriptionId}`
                      )
                    "
                  />
                </div>
                <div class="flex items-center">
                  <icon
                    name="reuse"
                    class="cursor-pointer"
                    @click="
                      refreshSubscription(subscription.zohoSubscriptionId)
                    "
                  />
                </div>
              </div>
            </div>
            <div class="mt-2 separator" />
          </div>
        </div>
      </div>
      <div
        v-else
        class="px-5 py-2 border w-1/5 border-violet text-violet rounded-lg cursor-pointer"
        @click="createSubscription"
      >
        {{ $t('subscriptions.create') }}
      </div>
    </l-card>
  </div>
</template>

<script>
import LCard from '@last/core-ui/components/LCard'
import Icon from '@last/core-ui/components/Icon.vue'
import api from '@/api.js'
import { mapState } from 'vuex'

export default {
  name: 'ZohoSubscriptions',
  data() {
    return {
      showSubscriptionModal: false
    }
  },
  props: {
    subscriptions: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    async refreshSubscription(subscriptionId) {
      try {
        await api.put(
          `/support/location/${this.selectedLocation.id}/subscription/${subscriptionId}/refresh`
        )
        this.$lnotification.create({
          title: this.$t('subscriptions.refresh-subscription-success'),
          iconColor: 'green'
        })
      } catch (err) {
        if (err.response) {
          this.$lnotification.create({
            title: `${this.$t('subscriptions.refresh-subscription-fail')}: ${
              err.response.request.response
            }`,
            icon: 'close',
            iconColor: 'red'
          })
        } else {
          throw new Error(err)
        }
      }
    },
    async createSubscription() {
      this.loading = true
      let response
      try {
        response = await api.post(
          `/support/location/${this.selectedLocation.id}/subscription`
        )
        this.$emit('created', response.data)
      } catch (e) {
        this.$lnotification.create({
          title: e.response.request.response,
          icon: 'error',
          iconColor: 'text-error'
        })
      }
      this.loading = false
    },
    goToUrl(url) {
      window.open(url)
    },
    handleUpdated() {
      this.$emit('updated')
      this.showSubscriptionModal = false
    }
  },
  computed: {
    ...mapState('organizations', ['selectedLocation'])
  },
  components: {
    LCard,
    Icon
  }
}
</script>

<style scoped>
.separator {
  height: 1px;
  background-color: #e5e5e5;
}
</style>
