export const errors = {
  inviteAlreadyAccepted: { code: 'INVITE_ALREADY_ACCEPTED', status: 404 },
  userAlreadyExists: { code: 'ER_DUP_ENTRY', status: 400 },
  userIsNotSupport: { code: 'USER_IS_NOT_SUPPORT', status: 403 },
  editWrongUser: { code: 'EDIT_WRONG_USER', status: 403 },
  wrongCurrentPassword: { code: 'WRONG_CURRENT_PASSWORD', status: 401 },
  locationNotAllowed: { code: 'LOCATION_NOT_ALLOWED', status: 403 },
  genericError: { code: 'GENERIC_ERROR', status: 400 },
  badCredentials: { code: 'BAD_CREDENTIALS', status: 401 },
  deviceDeleted: { code: 'DEVICE_DELETED', status: 401 },
  userNotFound: { code: 'USER_NOT_FOUND', status: 400 },
  recoveryTokenExpired: { code: 'RECOVERY_TOKEN_EXPIRED', status: 410 },
  tooManyTokensRequested: { code: 'TOO_MANY_REQUESTS', status: 429 }
}

export const sendHttpError = function (err, res) {
  res.status(err.status).send({ code: err.code })
}
